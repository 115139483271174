import React, { PureComponent } from "react";
import ShortId from "shortid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { icons } from "../../../assets";
import { operations } from "../../../utils/enum";
import { Button, ButtonColors } from "../../components/buttons";
import { Panel } from "../../components/panel";
import { ListItem } from "./ListItem";
import { ListItemOperation } from "./ListItemOperation";
import "./params.scss";

export class List extends PureComponent {
  state = {
    list: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setStateListFromProps();
    }
  }

  setStateListFromProps = (_) => {
    //Generate shortId to have a unique key for each item preventing list from re-rendering every time a modification occurs but rendering when an item is deleted
    const list = JSON.parse(JSON.stringify(this.props.data)).map((item) => {
      item.key = ShortId.generate();
      return item;
    });

    this.setState({ list });
  };

  handleValueChanged = (tag) => (index, value) => {
    const listCopy = JSON.parse(JSON.stringify(this.state.list));
    listCopy[index][tag] = value;

    // Reset onError state
    listCopy.map((item) => (item.onError = false));

    this.setState({ list: listCopy });
  };

  handleItemDeletion = (index) => {
    const listCopy = JSON.parse(JSON.stringify(this.state.list));
    listCopy.splice(index, 1);
    this.setState({ list: listCopy });
  };

  handleAddItem = (_) => {
    const listCopy = JSON.parse(JSON.stringify(this.state.list));
    listCopy.push({ key: ShortId.generate(), label: "" });
    this.setState({ list: listCopy });
  };

  handleDragEnded = (result, _) => {
    const listCopy = JSON.parse(JSON.stringify(this.state.list));
    const oldIndex = result.source.index;
    const oldIndexSuccess = listCopy[oldIndex];
    listCopy.splice(oldIndex, 1);
    listCopy.splice(result.destination.index, 0, oldIndexSuccess);
    this.setState({ list: listCopy });
  };

  handleSaveList = (_) => {
    const { isOperation, saveAction } = this.props;
    const listCopy = JSON.parse(JSON.stringify(this.state.list));

    // Validate list content
    let onError = false;
    listCopy.forEach((item) => {
      if (
        !item.label ||
        item.label.length === 0 ||
        (isOperation && !item.operation)
      ) {
        item.onError = true;
        onError = true;
      }
    });
    this.setState({ list: listCopy });

    if (!onError) {
      saveAction(listCopy);
    }
  };

  getList = () => {
    const { strings, isOperation, reordonnable } = this.props;
    const { list } = this.state;

    const operationList = Object.values(operations).map((ope) => {
      ope.value = { label: strings(ope.label) };
      return ope;
    });

    return (
      <ul className={`list`}>
        {list.map((value, index) => {
          if (isOperation) {
            const matchingOperation = operationList.find(
              (ope) => ope.key === value.operation
            );
            return (
              <ListItemOperation
                strings={strings}
                key={value.key}
                itemKey={value.key}
                index={index}
                label={value.label}
                operation={
                  matchingOperation ? matchingOperation.value.label : null
                }
                operationList={operationList}
                onError={value.onError}
                handleValueChanged={this.handleValueChanged("label")}
                handleOperationChanged={this.handleValueChanged("operation")}
                handleItemDeletion={this.handleItemDeletion}
              />
            );
          } else {
            return (
              <ListItem
                strings={strings}
                key={value.key}
                itemKey={value.key}
                index={index}
                label={value.label}
                reordonnable={reordonnable}
                onError={value.onError}
                handleValueChanged={this.handleValueChanged("label")}
                handleItemDeletion={this.handleItemDeletion}
              />
            );
          }
        })}
      </ul>
    );
  };
  render() {
    const { strings, title, reordonnable } = this.props;

    return (
      <Panel className={"success-container"}>
        <p className={"title"}>{title}</p>
        <hr />
        {reordonnable ? (
          <DragDropContext onDragEnd={this.handleDragEnded}>
            <Droppable droppableId={"list"}>
              {(provided, snapshot) => (
                <div className={"list-wrapper"} ref={provided.innerRef} {...provided.droppableProps}>
                  {this.getList()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className={"list-wrapper"}>
            {this.getList()}
          </div>
        )}
        <div className={"add-wrapper"}>
          <Button
            label={strings("add")}
            className={"add"}
            icon={icons.ADD}
            color={ButtonColors.orange}
            action={this.handleAddItem}
          />
        </div>
        <div className={"actions-wrapper"}>
          <Button
            label={strings("save")}
            className={"save"}
            color={ButtonColors.green}
            action={this.handleSaveList}
          />
          <Button
            label={strings("cancel")}
            className={"cancel"}
            color={ButtonColors.orange}
            action={this.setStateListFromProps}
          />
        </div>
      </Panel>
    );
  }
}
