import { methods } from '../utils';
import { authenticatedService } from './middleware';

const {GET, PUT} = methods;
const routes = {
  getSuccesses: adminId => `/admin/${adminId}/successes`,
  putSuccesses: adminId => `/admin/${adminId}/successes`,
  getOperationTypes: _ => `/operationTypes`,
  putOperationTypes: adminId => `/admin/${adminId}/operationTypes`,
  getClassCategories: _ => `/classCategories`,
  putClassCategories: adminId => `/admin/${adminId}/classCategories`,
  getClassLevels: _ => `/classLevels`,
  putClassLevels: adminId => `/admin/${adminId}/classLevels`,
  getClassSectors: _ => `/classSectors`,
  putClassSectors: adminId => `/admin/${adminId}/classSectors`,
  getDedicatedTimes: _ => `/dedicatedTimes`,
  putDedicatedTimes: adminId => `/admin/${adminId}/dedicatedTimes`,
};

export default {
  getSuccesses: adminId => authenticatedService(GET, routes.getSuccesses(adminId), {}, {}),
  putSuccesses: (adminId, body) => authenticatedService(PUT, routes.putSuccesses(adminId), body, {}),
  getOperationTypes: _ => authenticatedService(GET, routes.getOperationTypes(), {}, {}),
  putOperationTypes: (adminId, body) => authenticatedService(PUT, routes.putOperationTypes(adminId), body, {}),
  getClassCategories: _ => authenticatedService(GET, routes.getClassCategories(), {}, {}),
  putClassCategories: (adminId, body) => authenticatedService(PUT, routes.putClassCategories(adminId), body, {}),
  getClassLevels: _ => authenticatedService(GET, routes.getClassLevels(), {}, {}),
  putClassLevels: (adminId, body) => authenticatedService(PUT, routes.putClassLevels(adminId), body, {}),
  getClassSectors: _ => authenticatedService(GET, routes.getClassSectors(), {}, {}),
  putClassSectors: (adminId, body) => authenticatedService(PUT, routes.putClassSectors(adminId), body, {}),
  getDedicatedTimes: _ => authenticatedService(GET, routes.getDedicatedTimes(), {}, {}),
  putDedicatedTimes: (adminId, body) => authenticatedService(PUT, routes.putDedicatedTimes(adminId), body, {}),
};
