import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ParamsActions, ParamsSelectors } from '../../../redux/params';
import { Params } from './Params';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  successesM9: ParamsSelectors.getSuccessesM9(state),
  successesM13: ParamsSelectors.getSuccessesM13(state),
  successesL: ParamsSelectors.getSuccessesL(state),
  successesXL: ParamsSelectors.getSuccessesXL(state),
  successesS: ParamsSelectors.getSuccessesS(state),
  successesChallenge: ParamsSelectors.getSuccessesChallenge(state),
  operations: state.params.operations,
  categories: state.params.categories,
  levels: state.params.levels,
  sectors: state.params.sectors,
  times: state.params.times,
});

const mapDispatchToProps = dispatch => ({
  getSuccesses: () => dispatch(ParamsActions.getSuccessesRequested()),
  saveSuccesses: (type, list) => dispatch(ParamsActions.createSuccessesRequested(type, list)),
  getOperations: () => dispatch(ParamsActions.getOperationsRequested()),
  saveOperations: list => dispatch(ParamsActions.createOperationsRequested(list)),
  getCategories: () => dispatch(ParamsActions.getCategoriesRequested()),
  saveCategories: list => dispatch(ParamsActions.createCategoriesRequested(list)),
  getLevels: () => dispatch(ParamsActions.getLevelsRequested()),
  saveLevels: list => dispatch(ParamsActions.createLevelsRequested(list)),
  getSectors: () => dispatch(ParamsActions.getSectorsRequested()),
  saveSectors: list => dispatch(ParamsActions.createSectorsRequested(list)),
  getTimes: () => dispatch(ParamsActions.getTimesRequested()),
  saveTimes: list => dispatch(ParamsActions.createTimesRequested(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Params);
