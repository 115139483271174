import moment from "moment";

export default {
  /* Navigation */
  inscriptions: "Inscriptions",

  /*common */
  add: "Ajouter",
  save: "Enregistrer",
  cancel: "Annuler",
  select: "Selectionner",
  validate: "Valider",
  edit: "Modifier",
  delete: "Supprimer",
  archive: "Archiver",
  download: "Télécharger",
  download_archive: "Télécharger les archives",
  create: "Créer",
  create_one: "Création d'un ",
  give_up: "Abandonner",
  link_one_more: "Lier un ou des",
  link_one: "Lier un ",
  input_require: "Ce champs est obligatoire",
  input_invalid: "Ce champs est invalide",
  wrong_email: "Format d'email invalide",
  wrong_phone: "Format de numéro invalide",
  wrong_rne: "Format du code Uai invalide",
  previous: "Précédent",
  next: "Suivant",
  not_filled: "N.C",
  select_all: "Tout sélectionner",
  my_projects: "Mes projets",

  /* Enum */
  mr: "Monsieur",
  mrs: "Madame",

  /* Paramètres */
  successes: "Succès",
  operation_types: "Types d'opérations",
  class_categories: "Catégories de classes",
  class_levels: "Niveaux de classes",
  class_sectors: "Filières",
  dedicated_times: "Temps dédiés",
  operation: "Opération",
  withdraw: "Dépense",
  deposit: "Recette",
  error_operation: "Veuillez renseigner une opération",

  /* Success */
  m9_success: "Succès des projets Mini M 9-12",
  m13_success: "Succès des projets Mini M 13-25",
  long_success: "Succès des projets Mini L",
  xlong_success: "Succès des projets Mini XL",
  short_success: "Succès des Mini S",
  challenge_success: "Succès des projets Challenge 10X",

  /* SnackBar Message */
  success_retrieval_failed:
    "La récupération des succès a échoué, veuillez réessayer plus tard",
  success_update_failed:
    "La mise à jour des succès a échoué, veuillez réessayer plus tard",
  operations_retrieval_failed:
    "La récupération des types d'opérations a échoué, veuillez réessayer plus tard",
  operations_update_failed:
    "La mise à jour des types d'opérations a échoué, veuillez réessayer plus tard",
  categories_retrieval_failed:
    "La récupération des catégories de classes a échoué, veuillez réessayer plus tard",
  categories_update_failed:
    "La mise à jour des catégories de classes a échoué, veuillez réessayer plus tard",
  levels_retrieval_failed:
    "La récupération des catégories de classes a échoué, veuillez réessayer plus tard",
  levels_update_failed:
    "La mise à jour des catégories de classes a échoué, veuillez réessayer plus tard",
  sectors_retrieval_failed:
    "La récupération des filières a échoué, veuillez réessayer plus tard",
  sectors_update_failed:
    "La mise à jour des filières a échoué, veuillez réessayer plus tard",
  times_retrieval_failed:
    "La récupération des temps dédiés a échoué, veuillez réessayer plus tard",
  times_update_failed:
    "La mise à jour des temps dédiés a échoué, veuillez réessayer plus tard",
  school_inscription_retrieval_failed:
    "La récupération des inscriptions d'établissements a échoué, veuillez réessayer plus tard",
  school_reinscription_retrieval_failed:
    "La récupération des établissements en cours de réinscription a échoué, veuillez réessayer plus tard",
  mentor_inscription_retrieval_failed:
    "La récupération des inscriptions de mentors a échoué, veuillez réessayer plus tard",
  mentors_retrieval_failed:
    "La récupération de la liste des mentors a échoué, veuillez réessayer plus tard",
  facilitators_retrieval_failed:
    "La récupération de la liste des facilitators a échoué, veuillez réessayer plus tard",
  supervisors_retrieval_failed:
    "La récupération de la liste des encadrants a échoué, veuillez réessayer plus tard",
  freemiums_retrieval_failed:
    "La récupération de la liste des freemiums a échoué, veuillez réessayer plus tard",
  users_retrieval_failed:
    "La récupération de la liste des utilisateurs a échoué, veuillez réessayer plus tard",
  school_detail_retrieval_failed:
    "La récupération de l'établissement a échoué, veuillez réessayer plus tard",
  schools_retrieval_failed:
    "La récupération de la liste des établissements a échoué, veuillez réessayer plus tard",
  school_update_failed:
    "La mise à jour de l'établissement a échoué, veuillez réessayer plus tard",
  school_creation_failed:
    "La création de l'établissement a échoué, veuillez réessayer plus tard",
  school_already_exist:
    "Un établissement est déjà enregistré sous ce numéro Uai.",
  mentor_update_failed:
    "La mise à jour du mentor a échoué, veuillez réessayer plus tard",
  facilitator_update_failed:
    "La mise à jour du facilitateur a échoué, veuillez réessayer plus tard",
  freemium_update_failed:
    "La mise à jour de l'utilisateur freemium a échoué, veuillez réessayer plus tard",
  surpervisor_update_failed:
    "La mise à jour de l'encadrant a échoué, veuillez réessayer plus tard",
  mentor_detail_retrieval_failed:
    "La récupération du mentor a échoué, veuillez réessayer plus tard",
  project_update_failed:
    "La mise à jour du projet a échoué, veuillez réessayer plus tard",
  project_creation_failed:
    "La création du projet a échoué, veuillez réessayer plus tard",
  project_creation_success:
    "Votre projet a bien été créé et va s’afficher en haut de la liste.",
  school_creation_success: "L'établissement a bien été créé",
  mentor_creation_failed:
    "La création du mentor a échoué, veuillez réessayer plus tard",
  facilitator_creation_failed:
    "La création du facilitateur a échoué, veuillez réessayer plus tard",
  freemium_creation_failed:
    "La création de l'utilisateur freemium a échoué, veuillez réessayer plus tard",
  surpervisor_creation_failed:
    "La création de l'encadrant a échoué, veuillez réessayer plus tard",
  account_already_exists:
    "Un compte utilisateur est déjà associé à cet e-mail.",
  school_deletion_failed:
    "La suppression de l'établissement a échoué, veuillez réessayer plus tard",
  school_validation_failed:
    "La validation de l'établissement a échoué, veuillez réessayer plus tard",
  school_convention_failed:
    "L'édition de la convention de l'établissement a échoué, veuillez réessayer plus tard",
  mentor_validation_failed:
    "La validation du mentor a échoué, veuillez réessayer plus tard",
  mentor_deletion_failed:
    "La suppression du mentor a échoué, veuillez réessayer plus tard",
  facilitator_deletion_failed:
    "La suppression du facilitateur a échoué, veuillez réessayer plus tard",
  freemium_deletion_failed:
    "La suppression de l'utilisateur freemium a échoué, veuillez réessayer plus tard",
  supervisor_deletion_failed:
    "La suppression de l'encadrant a échoué, veuillez réessayer plus tard",
  delete_school_failed:
    "La suppression de l'établissement a échoué, veuillez réessayer plus tard",
  delete_project_failed:
    "La suppression du projet a échoué, veuillez réessayer plus tard",
  archive_project_failed:
    "L'archivage du projet a échoué, veuillez réessayer plus tard",
  archive_projects_failed:
    "L'archivage des projets a échoué, veuillez réessayer plus tard",
  change_to_finished_projects_failed:
    "Le changement du status des projets a échoué, veuillez réessayer plus tard",
  freemium_upgrade_failed:
    "L'amélioration de l'utilisateur freemium a échoué, veuillez réessayer plus tard",
  freemium_upgrade_succeed:
    "L'amélioration de l'utilisateur freemium a été effectuée",
  delete_school_succeeded: "La suppression de l'établissement a été effectuée",
  delete_project_succeeded: "La suppression du projet a été effectuée",
  archive_project_succeeded: "Le projet a bien été archivé",
  archive_projects_succeeded: "Les projets ont bien été archivés",
  change_to_finished_projects_succeeded: "Le status des projets a bien été changé",
  want_send_mail_mentor: "Voulez-vous envoyer un mail à ce mentor ?",
  want_send_mail_mentors: "Voulez-vous envoyer un mail à ces mentors ?",
  want_send_mail_facilitator: "Voulez-vous envoyer un mail à ce facilitateur ?",
  want_send_mail_facilitators:
    "Voulez-vous envoyer un mail à ces facilitateurs ?",
  want_send_mail_supervisor: "Voulez-vous envoyer un mail à cet encadrant ?",
  want_send_mail_supervisors: "Voulez-vous envoyer un mail à ces encadrants ?",
  want_send_mail_freemium: "Voulez-vous envoyer un mail à ce freemium ?",
  want_send_mail_freemiums: "Voulez-vous envoyer un mail à ces freemiums ?",
  want_archive_project: "Voulez-vous archiver ce project ?",
  want_archive_projects: "Voulez-vous archiver ces projects ?",
  want_finish_project: "Voulez-vous passer ce projet en status \"terminé\"",
  want_finish_projects: "Voulez-vous passer ces projets en status \"terminé\"",
  export_failed: "L'export a échoué, veuillez réessayer plus tard",

  /* ConfirmPopup Message */
  confirm_warning: "Avant de continuer",
  confirm_delete_project:
    "Êtes vous sûr de vouloir supprimer ce projet, cette action sera irréversible ?",
  confirm_archive_project:
    'Le projet sera archivé et les données du "dashboard" conservées 90 jours.\n' +
    "Les encadrants et mentors associés seront informés par email de l'archivage du projet.\n" +
    "\n" +
    "Cette action est irréversible.",
  confirm_archive_projects:
    'Les projets sélectionnés seront archivés et les données du "dashboard" conservées 90 jours.\n' +
    "Les encadrants et mentors associés seront informés par email de l'archivage du projet.\n" +
    "\n" +
    "Cette action est irréversible.",
  confirm_finished_projects:
    "Les projets seront passés au statut Terminé et les données du “dashboard” seront toujours consultables après cette mise à jour.",
  confirm_delete_mentor:
    "Êtes vous sûr de vouloir supprimer ce mentor, cette action sera irréversible ?",
  confirm_delete_supervisor:
    "Êtes vous sûr de vouloir supprimer cet encadrant, cette action sera irréversible ?",
  confirm_delete_facilitator:
    "Êtes vous sûr de vouloir supprimer ce facilitateur, cette action sera irréversible ?",
  confirm_delete_school:
    "Êtes vous sûr de vouloir supprimer cet établissement, cette action sera irréversible ?",
  confirm_delete_freemium:
    "Êtes vous sûr de vouloir supprimer cet utilisateur freemium, cette action sera irréversible ?",

  /* listitem */
  mentors: "Mentors",
  supervisors: "Encadrants",
  schools: "Établissements",
  facilitators: "Facilitateurs",
  freemiums: "Freemiums",
  projects: "Projets",
  notifications: "Notifications",
  record: "Dossier",
  project: "projet",
  academy: "Académie",
  status: "Statut",
  int: "Intermédiaire",
  long: "Long",
  back: "Retour",

  /* Project - UsersList */
  button_text_add: "Ajouter",
  button_text_modify: "Modifier",
  button_text_save: "Sauvegarder",
  upgrade_supervisor: "Améliorer en Encadrant",
  upgrade_mentor: "Améliorer en Mentor",
  upgrade_supervisor_description:
    "Veuillez renseigner les champs ci-dessous pour remplir le profil encadrant de l'utilisateur",
  upgrade_mentor_description:
    "Veuillez renseigner les champs ci-dessous pour remplir le profil mentor de l'utilisateur",
  upgrade_warning:
    "Attention, cette action est irréversible. Voulez-vous poursuivre ?",

  /* Project details */
  all_project: "Tous les projets",
  details: "Détails",
  users: "Utilisateurs",
  user: "Utilisateur",
  school: "Établissement",
  no_users: "Aucun utilisateur pour le moment",
  no_projects: "Aucun projet pour le moment",
  type: "Type",
  password: "Mot de passe",
  code: "Code",
  project_status: "Statut",
  project_status_all: "Tous",
  project_status_validated: "En cours",
  project_status_archived: "Archivé",
  project_status_finished: "Terminé",
  project_status_comming: "À venir",
  project_status_pending: "En attente",
  season: "Année scolaire",
  season_year: "Année",
  duration: "Durée",
  duration_one_year: "1 année scolaire",
  duration_several_years: "années scolaires",
  project_default_name: "Nom de projet par défaut",
  project_name: "Nom de projet",

  /* User Screen */
  supervisor_retrieval_failed: "Encadrant introuvable",
  facilitator_retrieval_failed: "Facilitateur introuvable",
  freemium_retrieval_failed: "Utilisateur freemium introuvable",
  search: "Recherche",
  firstname: "Prénom",
  lastname: "Nom",
  phone_number: "Numéro de téléphone",
  rights: "Type de compte",
  user_info: "Informations utilisateur *",
  perimeter: "Périmètre d'action *",
  description: "Description",
  company: "La société *",
  company_ceo: "Dirigeant",
  company_name: "Raison sociale",
  admin_rights: "Droits administrateur",
  all_facilitator: "Tous les facilitateurs",
  all_freemiums: "Tous les freemiums",
  all_mentor: "Tous les mentors",
  all_supervisor: "Tous les encadrants",

  all: "Tous",
  mme: "MMe",
  regions: "Régions",
  academies: "Academies",
  progression: "Progression",
  types: "Types",
  statuts: "Statuts",

  draft: "En cours",
  pending: "A traiter",
  waiting: "En attente",
  validated: "Valide",
  accepted: "Acceptée",
  warning: "Attention",
  ok: "OK",
  no_schools: "Aucun établissement pour le moment",
  no_supervisors: "Aucun encadrant pour le moment",
  no_facilitators: "Aucun facilitateur pour le moment",
  no_mentors: "Aucun mentor pour le moment",
  no_user: "Aucun utilisateur",
  no_freemiums: "Aucun freemium pour le moment",
  send: "Envoyer",

  pourcent_filter_all: "Tous",
  pourcent_filter_xsmall: "0 - 20%",
  pourcent_filter_small: "21 - 40%",
  pourcent_filter_medium: "41 - 60%",
  pourcent_filter_large: "61 - 80%",
  pourcent_filter_xlarge: "81 - 100%",

  l: "Mini L",
  xl: "Mini XL",
  m9: "Mini M 9-12",
  m13: "Mini M 13-25",
  challenge: "Challenge 10X",
  project_m9_age: "9-12 ans",
  project_m13_age: "13-25 ans",

  /* School - ProjectsList */
  all_school: "Tous les établissements",
  address: "Adresse",
  postalCode: "Code postal",
  city: "Ville",
  region: "Région",
  country: "Pays",
  secretariatPhone: "Numéro de téléphone du secrétariat",
  civility: "Civilité",
  firstName: "Prénom",
  lastName: "Nom",
  email: "Email",
  phone: "Numéro de téléphone",
  headmaster: "Chef d’établissement",
  referent: "Référent établissement",
  referent_disclaimer:
    "Ce contact sera notre référent pour gérer les conventions et les futures réinscription entre votre établissement et EPA",
  rne: "Uai",
  school_name: "Nom de l'établissement",
  school_default_name: "Nom de l'établissement par défaut",
  school_convention_action: "Editer la convention",
  school_validate_action: "Valider l'établissement",
  school_delete_action: "Supprimer l'établissement",
  mentor_validate_action: "Valider le mentor",
  mentor_delete_action: "Supprimer le mentor",
  facilitator_delete_action: "Supprimer le facilitateur",
  supervisor_delete_action: "Supprimer l'encadrant",
  freemium_delete_action: "Supprimer le freemium",
  project_delete_action: "Supprimer le projet",
  project_archive_action: "Archiver le projet",
  project_access_action: "Accéder au projet",
  send_mail_action: "Envoyer un mail",
  no_name_project: "Projet sans nom",
  record_info: "Informations du dossier",
  last_update: "Dernière mise à jour",
  record_ref: "Référence dossier",

  one_or_more_link_project_failed:
    "L'ajout d'un ou plusieurs projet(s) a echoué",
  unlink_project_failed: "Le retrait du projet a echoué",
  one_or_more_link_user_failed:
    "L'ajout d'un ou plusieurs utilisateur(s) a echoué",
  unlink_user_failed: "Le retrait de l'utilisateur a echoué",

  // Rights
  rights_freemium: "Freemium",
  rights_premium: "Premium",
  rights_pending: "En attente",

  /* FOOTER */
  copyright: `© ${moment().year()} Entreprendre pour Apprendre`,
  access_to: "Accédez au site",

  /* legal mentions */
  legal_mentions: "Mentions Légales",
  private_policy: "Politique de confidentialité",
  cgu: "CGU",
  intellectual_property: "Propriété intellectuelle",
  legal_mentions_contact:
    "La plateforme pédagogique EPA est développée par :\n\n" +
    "Entreprendre Pour Apprendre France\n" +
    "La Filature – 32 rue du Faubourg Poissonnière\n" +
    "75010 Paris\n" +
    "\n" +
    "Numéro SIREN : 449 242 049\n\n" +
    "Contact : contact@epa-france.fr\n\n" +
    "EPA France est une association loi 1901 reconnue d’intérêt général et titulaire d’un agrément du Ministère de l’Education et de la Jeunesse.",
  private_policy_complete:
    "Politique de confidentialité et de traitement des données personnelles",
  private_policy_text:
    "Les informations recueillies par Entreprendre pour Apprendre France (EPA France) à partir de cette plateforme pédagogique font l’objet d’un traitement informatique destiné au réseau EPA. Il s’agit des coordonnées des encadrants, des mentors et des établissements dans lesquels se mettent en place des programmes pédagogiques EPA.\n\n" +
    "Cette collecte de données a pour finalité de procéder aux inscriptions des établissements dans les parcours Mini-Entreprise M® et Mini-Entreprise L®, mais également de pouvoir réaliser des statistiques sur notre action.\n\n" +
    "Ces données nominatives des encadrants et des mentors seront conservées par EPA France au maximum 2 ans après le dernier contact.\n\n" +
    "Afin de protéger la confidentialité de vos données personnelles, EPA France s’engage à ne pas transmettre ni partager vos données avec d’autres entités, entreprises ou organismes extérieurs au réseau EPA.\n\n" +
    "Conformément à la loi Informatique et libertés du 6 janvier 1978 modifiée et aux dispositions du Règlement Général sur la Protection des Données Personnelles du 27 avril 2016, vous disposez d’un droit d’accès, de rectification, de portabilité et d’effacement de vos données personnelles.\n\n" +
    "Vous pouvez vous opposer au traitement de vos données et disposez du droit de retirer votre consentement à tout moment.\n\n" +
    "Pour exercer vos droits, vous pouvez vous adresser à : ",
  private_policy_contact: "contact@epa-france.fr",
  cgu_complete:
    "Conditions générales d'utilisation de la plateforme pedagogique EPA",
  cgu_presentation: "Présentation de la plateforme pedagogique EPA",
  cgu_presentation_text:
    "La plateforme pédagogique EPA a pour objectif de permettre aux encadrants, jeunes et mentors participant à un programme pédagogique EPA de suivre leur Mini-Entreprise® tout au long de l’année en mode gestion de projet.\n\n" +
    "Elle se présente sous la forme d’un espace dédié à chaque projet de Mini-Entreprise® et propose plusieurs types d’outils pédagogiques (documents, vidéos …) permettant aux jeunes et à leurs encadrant et mentor de mener à bien leur projet.\n\n" +
    "L’accès à l’espace dédié au projet se fait via un login et un mot de passe.\n\n" +
    "Toutes les ressources présentes sur la plateforme pédagogique sont consultables sur ordinateur, tablette et smartphone, en multi-accès.\n\n",
  cgu_inscription: "Inscription",
  cgu_inscription_text:
    "Toute personne a accès à un contenu freemium de la plateforme pédagogique EPA en laissant ses nom, prénom et contact mail.\n\n" +
    "Pour accéder au contenu Premium, et donc à l’ensemble des ressources pédagogiques de la plateforme, il est nécessaire de faire une demande d’inscription en indiquant les coordonnées de l’établissement qui va accueillir le programme EPA.\n\n" +
    "Un facilitateur de l’association régionale EPA compétente prendra contact avec vous pour finaliser l’inscription et vous transmettre la convention qui sera signée entre l’établissement et EPA pour la mise en place du programme EPA.\n\n",
  cgu_uses: "Utilisation",
  cgu_uses_text:
    "L’accès premium à la plateforme se fait grâce à un login et un mot de passe qui sont communiqués une fois que la convention entre l’établissement et EPA est signée.\n\n" +
    "L’utilisateur a alors accès à toutes les ressources pédagogiques présentes sur la plateforme et à son espace projet qui lui est dédié. Il peut alors consulter les documents et vidéos en ligne et les télécharger.\n\n" +
    "Nous attirons votre attention sur le fait que le téléchargement des documents enlève le caractère interactif des documents.\n\n" +
    "L’utilisateur s’engage à :\n\n" +
    "-\tne pas communiquer ses identifiants à des personnes extérieures au projet EPA,\n" +
    "-\tutiliser les ressources pédagogiques dans le strict cadre du projet EPA,\n" +
    "-\tne pas utiliser les ressources proposées à des fins commerciales.\n\n" +
    "EPA France se réserve le droit de modifier ou de corriger, à tout moment et ce sans préavis, le contenu de sa plateforme et des documents qui sont proposés, et ne peut être tenue pour responsable des conséquences éventuelles de ces modifications. \n\n",
  cgu_technical: "Problème technique",
  cgu_technical_text:
    "En cas de problème technique dans l’utilisation de la plateforme pédagogique EPA, ou en cas de perte de vos identifiants, merci de contacter EPA France : <b>plateforme@epa-france.fr</b>",
  intellectual_property_complete: "Propiété intellectuelle et copyright",
  intellectual_property_licence:
    "Les outils et supports sont sous licence Créative Commons <b>BY NC ND</b>",
  intellectual_property_mentions:
    "Les mentions liées à Entreprendre pour Apprendre font intégralement partie des supports",
  intellectual_property_commercial:
    "Toute utilisation à des fins commerciales est passible de poursuites judiciaires",
  intellectual_property_shared:
    "Les supports ne peuvent être intégrés partiellement ou en totalité dans un autre support. Ils ne peuvent être partagés qu’en intégralité (fond et forme)",
  intellectual_property_text:
    "La structure générale, les textes, images, logo, vidéos, sons, savoir-faire, animations, documents téléchargeables, et plus généralement toutes les informations et contenus figurant sur la plateforme pédagogique EPA, sont la propriété d’EPA France ou font l’objet d’un droit d’utilisation ou d’exploitation.\n\n" +
    "Toute représentation, modification, reproduction, dénaturation, totale ou partielle, de tout ou partie de la plateforme ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la Propriété Intellectuelle.",

  /* Reinscription */
  manual_reinscription: "Réinscription manuelle",
  projects_handling: "Gestion des projets",
  step_one_on_two: "1/2",
  step_two_on_two: "2/2",
  select_projects: "Sélectionnez les projets à conserver",
  select_projects_warning: "Les projets non conservés seront archivés.",
  select_projects_final_warning:
    "Les projets sélectionnées « non conservées » seront archivés et les donées du « dashboard » supprimés.\n" +
    "Les encadrants et mentors associées seront informés par mail de l'archivage du projet.\n" +
    "\n" +
    "Cette action est irréversible",
  validate_go_next_step: "Valider et passer à l’étape suivante",
  kept_projects: "Projets conservés",
  add_projects: "Ajouter de nouveaux projets",
  project_title: "Nouveau projet #",
  add_user: "Ajouter un utilisateur",
  add_existing_supervisor: "encadrant existant",
  add_new_supervisor: "nouvel encadrant",
  add_mentor: "mentor",
  attached_to_school: "Rattachés à cet établissement",
  all_mentors: "Tous les mentors",
  all_supervisors: "Tous les encadrants",
  add_supervisors: "Ajouter des encadrants",
  add_mentors: "Ajouter des mentors",
  add_project: "Ajouter autre projet",
  conflict_message: "Cet email est déjà associé à un autre utilisateur",
  duplicate_email: "Email déjà renseigné",
  supervisor: "Encadrant",
  mentor: "Mentor",
  facilitator: "Facilitateur",
  project_error_no_type: "Le type du projet doit être renseigné",
  project_error_no_duration: "N’oubliez pas de renseigner la durée",
  project_error_no_season: "N’oubliez pas de renseigner la saison",
  project_error_no_user: "Au moins un encadrant doit être associé au projet",
  project_error_users_duplicate: "Des utilisateurs apparaissent en double",
  reinscription_validation: "Votre inscription",
  new: "Nouveaux",
  previous_projects: "de la saison précédente",
  confirm: "Confirmer",
  confirm_and_edit: "Confirmer et editer la convention",
  give_up_warning:
    "Attention, en abandonnant maintenant, vous perdrez vos informations non enregistrées.\n\nSouhaitez-vous poursuivre ?",
  reinscription_failed:
    "La demande de réinscription a échouée, veuillez réessayer plus tard",
  edit_convention_failed:
    "L'édition de la convention a échoué, veuillez réessayer plus tard",
  reinscription_succedded:
    "La demande de réinscription a bien été prise en compte",
  new_inscriptions: "Nouvelles inscriptions",
  reinscriptions: "Réinscriptions",

  /* Notification Creation */
  new_message: "Nouveau message",
  recipients: "Destinataires",
  add_recipients: "Ajouter des destinataires",
  select_recipients: "Sélectionner les destinataires",
  recipients_message: "Ce message est destiné aux encadrants et aux mentors",
  allow_students: "Inclure les élèves",
  courses: "Parcours",
  message: "Message",
  title: "Titre",
  content: "Contenu",
  button: "Bouton",
  link: "Lien",
  test: "Tester",
  error_no_recipient: "Veuillez ajouter au moins un destinataire",
  error_no_course:
    "Veuillez destiner votre notification à un ou plusieurs parcours",
  error_no_title: "Veuillez renseigner le titre de la notification",
  error_no_description: "Veuillez renseigner le contenu de la notification",
  error_no_link: "Veuillez renseigner le lien de votre bouton",
  error_invalid_link: "Veuillez renseigner un lien valide pour votre bouton",
  error_no_link_title: "Veuillez renseigner le titre de votre bouton",
  create_notif_failed:
    "La création de la notification a échoué, veuillez réessayer plus tard",

  /* Notification list */
  notif_from: "De :",
  notif_to: "À :",
  no_notif: "Aucune notification pour le moment",
  get_notif_failed:
    "La récupération des notifications a échoué, veuillez réessayer plus tard",
  notif_delete_action: "Supprimer la notification",
  confirm_delete_notif:
    "Êtes vous sûr de vouloir supprimer cette notification, cette action sera irréversible ?",
  confirm_delete_notifs:
    "Êtes vous sûr de vouloir supprimer ces notifications, cette action sera irréversible ?",
  notif_duplicate_action: "Dupliquer",
  delete_notifs_failed:
    "La suppression des notifications à échoué, veuillez réessayer plus tard",
  want_delete_notif: "Êtes vous sûr de vouloir supprimer cette notification ?",
  want_delete_notifs: "Êtes vous sûr de vouloir supprimer ces notifications ?",

  /* Mini List */
  projects_l_m: "Projets XL, L et M",
  projects_mini_s: "Projets Mini-S",
  mini_list_retrieval_error:
    "Une erreur est survenue lors de la récupération de la liste de mini entreprises",
  no_minis: "Aucune mini entreprise pour le moment",
  date: "Date",
  online: "En ligne",
  participants: "Participants",
  confirm_delete_mini:
    "Êtes vous sûr de vouloir supprimer cette mini entreprise, cette action sera irréversible ?",
  confirm_archive_mini:
    'La mini entreprise sera archivée et les données du "dashboard" seront supprimées.\n' +
    "Cette action est irréversible.",
  mini_draft: "En préparation",
  mini_running: "En cours",
  mini_finished: "Terminée",
  mini_archived: "Archivée",

  /* Mini Enterprise */
  duplicate: "Dupliquer",
  mini_detail_retrieval_error:
    "Une erreur est survenue lors de la récupération de la mini entreprises",
  teams: "Équipes",
  tools: "Outils et contenus",
  rooms: "Salles",
  distance: "Distanciel",
  presence: "Présentiel",
  date_duration: "Dates et Durée",
  date_placeholder: "JJ/MM/AAAA",
  start_date: "Début",
  end_date: "Fin",
  duration_hours: "Heures",
  partners: "Partenaires",
  partner_name: "Nom de l'entreprise",
  subject: "Problématique",
  location: "Lieux",
  place_name: "Nom du lieux",
  mini_name_error: "Le nom du projet est obligatoire",
  mini_duration_invalid: "Durée invalide",
  partner_name_error: "Le nom du partenaire est obligatoire",
  add_default_successes: "Ajouter les succès par défaut",
  partner_firstname_invalid: "Prénom du contact invalide",
  partner_lastname_invalid: "Nom du contact invalide",
  total_students_school: "Nombre de jeunes",
  total_students_mini: "Nombre total de jeunes",
  add_group: "Ajouter un groupe",
  group_number: "Groupe #",
  category: "Catégorie",
  level: "Niveau de classe",
  sector: "Filière",
  girls_count: "Nombre de filles",
  boys_count: "Nombre de garçons",
  mentors_count: "Nombre de mentors",
  supervisors_count: "Nombre d'encadrants",
  common_tools: "Outils communs",
  room_tools: "Outils des salles",
  video_tools: "Vidéos",
  choose_icon: "Choisir une icône",
  tool_title: "Titre de l'outil",
  tool_url: "Lien de l'outil",
  tool_video: "Iframe de la vidéo",
  tool_label_error: "Le label de l'outil est obligatoire",
  tool_link_invalid: "L'url n'est pas valide",
  total_students_rooms: "Nombre de jeunes",
  room_show_progress: "Montrer <br/>l’avancement <br/>des salles",
  add_room: "Ajouter une salle",
  students: "Jeunes",
  room_number: "Salle #",
  button_launch: "Lancer",
  button_end: "Terminer",
  button_duplicate: "Dupliquer",
  mini_launch_error_title: "Impossible de lancer le projet",
  mini_launch_error: "Une ou plusieurs erreurs ont été repérées : <br/><br/>",
  mini_missing_start_date: "- La date de début n'est pas renseignée",
  mini_missing_subject: "- La problématique n'est pas renseignée",
  mini_missing_partner: "- Aucun partenaire renseigné",
  mini_missing_school: "- Aucun établissement associé",
  mini_missing_group:
    "- Certains établissements ne disposent d'aucun groupe renseigné",
  mini_invalid_group: "- Certains groupes ne sont pas complétés",
  mini_invalid_tool: "- Certains outils ne sont pas duement renseignés",
  mini_missing_room: "- Aucune salle déclarée pour la mini distancielle",
  mini_invalid_room_tool:
    "- Certains outils des salles ne sont pas duement renseignés",
  mini_duplication_error:
    "Une erreur est survenue lors de la duplication de la mini entreprise",
  mini_creation_error: "La création de la mini entreprise a échoué",
  mini_deletion_error: "La suppression de la mini entreprise a échoué",
  mini_deletion_success: "La suppression de la mini entreprise a été effectuée",
  mini_update_error: "La mise à jour de la mini entreprise a échoué",
  confirm_reset_mini:
    "Vous avez des modifications non enregistrées.<br/>En poursuivant ces dernières seront perdues.<br/>Voulez-vous continuer ?",
  confirm_no_save: "Oui, quitter ​sans sauvegarder",
  cancel_save: "Non, rester sur la page",

  generic_error: "Une erreur est survenue, veuillez réessayer plus tard",
  project_retrieval_failed: "Impossible de récupérer le projet",
  update_project_failed_has_success: "Impossible de changer le type de projet lorsque celui-ci à des succès validés"
};
