import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ParamsService } from '../../services';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as SuccessActions, types } from './actions';
import { ParamsActions } from './index';

function* getSuccessesRequested() {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.getSuccesses, id);

  if (error) {
    yield put(SnackActions.displayError('success_retrieval_failed'));
  } else {
    yield put(SuccessActions.getSuccessesSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createSuccessesRequested({successType, list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error] = yield call(ParamsService.putSuccesses, id, {type: successType, successes: list});

  if (error) {
    yield put(SnackActions.displayError('success_update_failed'));
    yield put(LoaderActions.loaded());
  } else {
    //TODO: is that necessary ?
    yield call(getSuccessesRequested);
  }
}

function* getOperationsRequested() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(ParamsService.getOperationTypes);

  if (error) {
    yield put(SnackActions.displayError('operations_retrieval_failed'));
  } else {
    yield put(SuccessActions.getOperationsSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createOperationsRequested({list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.putOperationTypes, id, list);

  if (error) {
    yield put(SnackActions.displayError('operations_update_failed'));
  } else {
    yield put(ParamsActions.createOperationsSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* getCategoriesRequested() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(ParamsService.getClassCategories);

  if (error) {
    yield put(SnackActions.displayError('categories_retrieval_failed'));
  } else {
    yield put(SuccessActions.getCategoriesSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createCategoriesRequested({list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.putClassCategories, id, list);

  if (error) {
    yield put(SnackActions.displayError('categories_update_failed'));
  } else {
    yield put(ParamsActions.createCategoriesSucceeded(response.data));
  }
  yield put(LoaderActions.loaded());
}

function* getLevelsRequested() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(ParamsService.getClassLevels);

  if (error) {
    yield put(SnackActions.displayError('levels_retrieval_failed'));
  } else {
    yield put(SuccessActions.getLevelsSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createLevelsRequested({list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.putClassLevels, id, list);

  if (error) {
    yield put(SnackActions.displayError('levels_update_failed'));
  } else {
    yield put(ParamsActions.createLevelsSucceeded(response.data));
  }
  yield put(LoaderActions.loaded());
}

function* getSectorsRequested() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(ParamsService.getClassSectors);

  if (error) {
    yield put(SnackActions.displayError('sectors_retrieval_failed'));
  } else {
    yield put(SuccessActions.getSectorsSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createSectorsRequested({list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.putClassSectors, id, list);

  if (error) {
    yield put(SnackActions.displayError('sectors_update_failed'));
  } else {
    yield put(ParamsActions.createSectorsSucceeded(response.data));
  }
  yield put(LoaderActions.loaded());
}

function* getTimesRequested() {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(ParamsService.getDedicatedTimes);

  if (error) {
    yield put(SnackActions.displayError('times_retrieval_failed'));
  } else {
    yield put(SuccessActions.getTimesSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* createTimesRequested({list}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(ParamsService.putDedicatedTimes, id, list);

  if (error) {
    yield put(SnackActions.displayError('times_update_failed'));
  } else {
    yield put(ParamsActions.createTimesSucceeded(response.data));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_SUCCESSES_REQUESTED, getSuccessesRequested),
  takeLatest(types.CREATE_SUCCESSES_REQUESTED, createSuccessesRequested),
  takeLatest(types.GET_OPERATIONS_REQUESTED, getOperationsRequested),
  takeLatest(types.CREATE_OPERATIONS_REQUESTED, createOperationsRequested),
  takeLatest(types.GET_CATEGORIES_REQUESTED, getCategoriesRequested),
  takeLatest(types.CREATE_CATEGORIES_REQUESTED, createCategoriesRequested),
  takeLatest(types.GET_LEVELS_REQUESTED, getLevelsRequested),
  takeLatest(types.CREATE_LEVELS_REQUESTED, createLevelsRequested),
  takeLatest(types.GET_SECTORS_REQUESTED, getSectorsRequested),
  takeLatest(types.CREATE_SECTORS_REQUESTED, createSectorsRequested),
  takeLatest(types.GET_TIMES_REQUESTED, getTimesRequested),
  takeLatest(types.CREATE_TIMES_REQUESTED, createTimesRequested),
];
